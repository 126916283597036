import React from "react";
import Reinvent1 from "./../../images/jacksplace.jpeg";
import Reinvent2 from "./../../images/Sleepout2.jpeg";

function ReinventingCommunications() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-16 md:mb-16 mb-8">
          <div className="md:block hidden">
            <div className="flex justify-center items-center">
              <img src={Reinvent1} alt="" className="w-full" />
            </div>
          </div>
          <div className="flex items-center">
            <div >
              <p className="text-xs font-normal leading-5 tracking-widest"></p>
              <h1 className="font-medium text-3xl mb-5">
                The Vonage Foundation
              </h1>
              <p className="font-normal text-lg mb-16">
                The Vonage Foundation supports global initiatives dedicated to
                closing the digital divide for children through partnerships,
                charitable contributions and donations of services for
                nonprofits.
              </p>
              <a
                href="/"
                className="border-b-2 border-black font-semibold text-sm tracking-widest "
              >
                LEARN MORE
              </a>
            </div>
          </div>
          <div className="md:hidden block">
            <div className="flex justify-center items-center">
              <img src={Reinvent1} alt="" className="w-full" />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-16 md:mb-16 mb-8">
          <div className="flex items-center">
            <div >
              <p className="text-xs font-normal leading-5 tracking-widest"></p>
              <h1 className="font-medium text-3xl mb-5">
                Our team member involvement
              </h1>
              <p className="font-normal text-lg mb-16">
                Vonage team members donated 52,000 volunteer hours in 2020. We
                also have many programs to support our employees and the causes
                near and dear to their hearts including on-site and virtual
                events, employee resource groups (ERGs) and matching
                contributions.
              </p>
              <a
                href="/"
                className="border-b-2 border-black font-semibold text-sm tracking-widest "
              >
                LEARN MORE
              </a>
            </div>
          </div>
          <div>
            <div className="flex justify-center items-center">
              <img src={Reinvent2} alt="" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReinventingCommunications;
