import React from "react";
import Headerp from "./../../images/clouds.webp";

function Header() {
  return (
    <div className="header-blue-pink">
      <div className="max-w-7xl m-auto md:py-12 py-8 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 ">
          <div className="mb-2 text-white">
            <span className="font-medium text-xs leading-6 tracking-wider mb-4">
              ABOUT US VONAGE CARES
            </span>

            <h1 className=" font-medium  lg:text-6xl md:text-5xl text-4xl mb-4 leading-none">
              Vonage Cares
            </h1>
            <p className="text-2xl mb-3 font-medium">
              Accelerating the world’s ability to connect
            </p>
            <p className="text-xl font-normal">
              Vonage is committed to reducing the digital divide by increasing
              global connectivity in a myriad of ways. Learn more about Vonage's
              Environmental, Social and Governance (ESG) principles and
              activities.
            </p>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
