import React from "react";
import Header from "./Header";
import ReinventingCommunications from "./ReinventingCommunications";

function Index({location}) {
  return (
    <div>
      <Header />
      <ReinventingCommunications />
    </div>
  );
}

export default Index;
